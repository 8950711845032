import * as actionTypes from 'store/types/system_calendar.action.types'

const initialState = {
  systemCalendars: [],
}

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case actionTypes.FETCH_SYSTEM_CALENDAR:
      return { ...state, systemCalendars: payload.data, total: payload.total }

    default:
      return state
  }
}

export default reducer
